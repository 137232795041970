import { defineComponent } from 'vue';
import RecaptchForm from '@/components/mixins/RecaptchaForm';
import { Services } from '@/store/actions/api';
import { jsonToFormData } from '@/utils/http-common';
import { Client } from '@/services';
import PrimaryButton from '../modules/login-static-plans/components/atoms/PrimaryButton.vue';
import { captureException } from '@sentry/vue';

export default defineComponent({
  name: 'ContactForm',

  components: {
    PrimaryButton,
  },

  mixins: [RecaptchForm],

  props: {
    narrow: {
      type: Boolean,
      default: true,
    },
    successMsg: {
      type: String,
      default: '$vuetify.forms.contact.successMsg',
    },
    errorMsg: {
      type: String,
      default: '$vuetify.forms.contact.errorMsg',
    },
  },

  data() {
    return {
      company: null,
      email: null,
      emailRules: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.email_required'),
        (v) => /.+@.+\..+/.test(v) || this.$vuetify.lang.t('$vuetify.alerts.email_not_valid'),
      ],
      firstName: null,
      lastName: null,
      message: null,
      messageRules: [(v) => !!v || this.$vuetify.lang.t('$vuetify.forms.contact.message_required')],
      phone: null,
      sending: false,
      valid: true,
      successMessage: null,
      errorMessage: null,
      messageText: null,
      messageType: null,
      messageColor: null,
    };
  },

  methods: {
    async submit() {
      if (this.$refs.contactForm.validate()) {
        const { firstName, lastName, email, phone, company, message } = this;
        this.sending = true;

        try {
          const params = {
            service: Services.FlightScopeEDH.key,
            method: Services.FlightScopeEDH.methods.SendSalesInquiry.key,
          };

          let payload = {
            firstName,
            lastName,
            email,
            phone,
            company,
            message,
          };

          if (this.enableRecaptcha) {
            payload.RC_ResponseToken = await this.recaptchaToken('contactForm');
          }

          const data = jsonToFormData(payload);

          const response = await Client({
            params,
            method: 'post',
            data,
          });

          this.reset();

          this.displayMessage(this.successMsg, 'success', 'green');
        } catch (error) {
          this.$log.debug(error);
          captureException(error);
          this.displayMessage(this.errorMsg, 'error');
        }
        this.sending = false;
      }
    },
    reset() {
      this.$refs.contactForm.reset();
    },
    displayMessage(text, type, color) {
      this.messageText = text;
      this.messageType = type;
      this.messageColor = color;

      setTimeout(() => {
        this.messageText = null;
        this.messageType = null;
        this.messageColor = null;
      }, 5000);
    },
  },
});
