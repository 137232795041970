<template>
  <VBtn v-bind="btnProps" v-on="events"><slot/></VBtn>
</template>


<script>
import { defineComponent } from 'vue';
import { defaultPrimaryBtnProps } from '../../config/global';

export default defineComponent({
  name: 'PrimaryButton',

  props: {
    disabled: { type: Boolean, default: false },
    events: { default: () => ({}) }
  },

  computed: {

    btnProps() {
      let atts =  {
        ...defaultPrimaryBtnProps,
        disabled: this.disabled,
        dark: !this.disabled,
        color: this.disabled ? '' : defaultPrimaryBtnProps.color,
        ...this.$attrs
      };
      return atts;
    }
  },
});
</script>
