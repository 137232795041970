export const defaultInputProps = Object.freeze({
  outlined: true,
  dense: true,
});

export const defaultBtnProps = Object.freeze({
  elevation: 0,
});

export const defaultPrimaryBtnProps = Object.freeze({
  ...defaultBtnProps,
  dark: true,
  color: 'primary',
});
